// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Alpine from 'alpinejs'
 
window.Alpine = Alpine
 
Alpine.start()

// window.test = () => {
//     console.log('test')
//  }
